import React from 'react'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import './customStyleA.css'
import { FaQuoteRight } from 'react-icons/fa'
import appStoreBadge from './appStoreBadge.png'
import playStoreBadge from './playStoreBadge.png'
import 'react-tabs/style/react-tabs.css'

const settings = {
  slidesToShow: 1,
  infinite: false,
  slidesToScroll: 1,
  autoplay: true,
  dots: true,
  arrows: false,
  speed: 2000
}

const Testimonials = () => {
  return (
    <div className='container'>
      <div class='col_wrap'>
        <div class='col-_gap'>
          <div class='testimonial_slider'>
            <Slider {...settings}>
              <div class='item'>
                <div class='ctnt'>
                  <div class='right_ctnt'>
                    <h4>Jihad Hassan</h4>
                    <h6>Managing Director of Lifco</h6>
                    <p>
                      I was looking for a solution that would allow me to create
                      an online marketplace for our multiple stores and lines of
                      business, and the Floki Marketplace Platform was the
                      perfect fit. The setup was easy, and the platform is
                      customizable and scalable to meet my needs.
                    </p>

                    <span>
                      <FaQuoteRight />
                    </span>
                  </div>
                </div>
              </div>
              <div class='item'>
                <div class='ctnt'>
                  <div class='right_ctnt'>
                    <h4>Maysoon Homidan</h4>
                    <h6>ERP Project Manager at Addoha Poultry Company</h6>
                    <p>
                      Your needs will be fulfilled with them! Floki D2C is an
                      easy-to-use, customizable, and secure platform. The
                      platform is a great gateway to reach new consumers,
                      control your sales, and increase your distribution
                      channels. The Floki Solutions team is great at providing
                      the necessary support and responding promptly.
                    </p>
                    <span>
                      <FaQuoteRight />
                    </span>
                  </div>
                </div>
              </div>
              <div class='item'>
                <div class='ctnt'>
                  <div class='right_ctnt'>
                    <h4>Joby Varghese</h4>
                    <h6>Business Unit Manager</h6>
                    <p>
                      The white label e-commerce solution has helped us to
                      expand our reach and increase our revenue. Our customers
                      love the convenience of online shopping, and we've been
                      able to streamline our operations and reduce our costs.
                    </p>

                    <span>
                      <FaQuoteRight />
                    </span>
                  </div>
                </div>
              </div>
              <div class='item'>
                <div class='ctnt'>
                  <div class='right_ctnt'>
                    <h4>Mohamad Fleifel</h4>
                    <h6>CEO of MAB Trading</h6>
                    <p>
                      Our ordering process has become much more efficient since
                      we started using this white label e-commerce solution. Our
                      customers appreciate the convenience and we've been able
                      to grow our business as a result.
                    </p>

                    <span>
                      <FaQuoteRight />
                    </span>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>

        <div class='col-_gap'>
          <div class='start_ctnt'>
            <h2>
              <span>Discover why our satisfied clients love our solution!</span>
            </h2>
            <p>
              Our market-tested solution has successfully processed thousands of
              orders, earning the trust of numerous satisfied clients.
            </p>
            <div class='payroll-btn'>
              <img src={playStoreBadge} alt='product_img' class='mr-img' />
              <img src={appStoreBadge} alt='product_img' class='mr-img2' />
              {/* <a href="#"><FaGooglePlay /></a>
                    <a href="#"><FaAppStore /></a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Testimonials
